import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faColumns,
  faBuilding,
  faHome,
  faWrench,
  faWaveSquare,
  faAsterisk,
  faUsers,
  faStar,
  faCog,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamation,
  faPen,
  faPlus,
  faPlusCircle,
  faSearch,
  faCompress,
  faMap,
  faMapMarkerAlt,
  faCheckCircle,
  faCheck,
  faBars,
  faBell,
  faEllipsisV,
  faCalendarAlt,
  faExchangeAlt,
  faSort,
  faGripLinesVertical,
  faTrash,
  faEnvelopeOpenText,
  faTimes,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faHistory,
  faEye,
  faEyeSlash,
  faCopy,
  faEdit,
  faSignInAlt,
  faSignOutAlt,
  faClock,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faBan,
  faUserCircle,
  faFilter,
  faCircle,
  faLock,
  faCookieBite,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCamera,
  faStickyNote,
  faClipboardList,
  faFile,
  faList,
  faStream,
  faFlag,
  faHandshake,
  faStopwatch,
  faTasks,
  faImage,
  faPaperclip,
  faHourglassEnd,
  faArrowDownToLine,
  faGrid,
  faPersonShelter,
  faGears,
  faSquareInfo,
  faLinkSimple,
  faUserGroup,
  faDesktop,
  faChartColumn,
  faArrowLeftToLine,
  faArrowRightToLine,
  faUserSecret,
  faHouseChimneyUser,
  faCloud,
  faCircleQuestion,
  faHelmetSafety,
  faHouseBuilding,
  faHouse,
  faCalendarExclamation,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faColumns,
  faBuilding,
  faHome,
  faWrench,
  faWaveSquare,
  faAsterisk,
  faUsers,
  faStar,
  faCog,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamation,
  faPen,
  faPlus,
  faPlusCircle,
  faSearch,
  faCompress,
  faMap,
  faMapMarkerAlt,
  faCheckCircle,
  faCheck,
  faBars,
  faBell,
  faEllipsisV,
  faCalendarAlt,
  faExchangeAlt,
  faSort,
  faGripLinesVertical,
  faTrash,
  faEnvelopeOpenText,
  faTimes,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faHistory,
  faEye,
  faEyeSlash,
  faCopy,
  faEdit,
  faSignInAlt,
  faSignOutAlt,
  faClock,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faBan,
  faUserCircle,
  faFilter,
  faCircle,
  faLock,
  faCookieBite,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCamera,
  faStickyNote,
  faClipboardList,
  faFile,
  faList,
  faStream,
  faFlag,
  faHandshake,
  faStopwatch,
  faTasks,
  faImage,
  faPaperclip,
  faHourglassEnd,
  faArrowDownToLine,
  faGrid,
  faPersonShelter,
  faGears,
  faSquareInfo,
  faLinkSimple,
  faUserGroup,
  faDesktop,
  faChartColumn,
  faArrowLeftToLine,
  faArrowRightToLine,
  faUserSecret,
  faHouseChimneyUser,
  faCloud,
  faHelmetSafety,
  faHouseBuilding,
  faHouse,
  faCircleQuestion,
  faCalendarExclamation
);
